import { useVirtualKeyboardContext } from "./../context/virtual-keyboard.context";
import { useFeaturesContext } from "app/context/features.context";
import { useEffect, useRef, useState } from "react";
import { getFontSizeMultiplier } from "app/utils/scroll";

export const NAVIGATION_OVERLAY_ATTRIBUTE = "data-navigate-overlay";
export const NAVIGATION_LAST_ACTIVE_ITEM_ATTRIBUTE = "data-navigate-last-active-item";
export const NAVIGATION_LAST_ACTIVE_ROW_ATTRIBUTE = "data-navigate-last-active-row";
export const NAVIGATION_ITEM_ATTRIBUTE = "data-navigate-item";
export const NAVIGATION_ROW_ATTRIBUTE = "data-navigate-row";
export const NAVIGATION_ROW_SELECTOR = `[${NAVIGATION_ROW_ATTRIBUTE}="true"]`;
export const NAVIGATION_GRID_ATTRIBUTE = "data-navigate-grid";
export const NAVIGATION_GRID_SELECTOR = `[${NAVIGATION_GRID_ATTRIBUTE}="true"]`;
export const NAVIGATION_SYNC_SCROLL_POSITION_ATTRIBUTE = "data-sync-scroll-position";
export const NAVIGATION_LAST_ACTIVE_ITEM_SELECTOR = `[${NAVIGATION_LAST_ACTIVE_ITEM_ATTRIBUTE}="true"]`;
export const NAVIGATION_LAST_ACTIVE_ROW_SELECTOR = `[${NAVIGATION_LAST_ACTIVE_ROW_ATTRIBUTE}="true"]`;
export const NAVIGATION_ITEM_SELECTOR = `[${NAVIGATION_ITEM_ATTRIBUTE}="true"]`


export const WEBOS_PLAY = 415;
export const WEBOS_PAUSE = 19;
export const WEBOS_FAST_FORWARD = 417;
export const WEBOS_REWIND = 412;
export const WEBOS_STOP = 413;
export const WEBOS_BACK = 461;

export function useArrowKeyNavigationOverlay(active: boolean = true) {
  const parentNode = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (active) {
      parentNode.current?.setAttribute(
        NAVIGATION_OVERLAY_ATTRIBUTE,
        document
          .querySelectorAll(`[${NAVIGATION_OVERLAY_ATTRIBUTE}]`)
          .length.toString()
      );
    } else {
      parentNode.current?.removeAttribute(NAVIGATION_OVERLAY_ATTRIBUTE);
      (Array.from(parentNode.current?.querySelectorAll(NAVIGATION_LAST_ACTIVE_ROW_SELECTOR) || []) as HTMLElement[])
        .forEach((item) => {
          item.removeAttribute(NAVIGATION_LAST_ACTIVE_ROW_ATTRIBUTE)
        });
      (Array.from(parentNode.current?.querySelectorAll(NAVIGATION_LAST_ACTIVE_ITEM_SELECTOR) || []) as HTMLElement[])
        .forEach((item) => {
          item.removeAttribute(NAVIGATION_LAST_ACTIVE_ITEM_ATTRIBUTE)
        })
    }
  }, [active]);

  return parentNode;
}

/**
 * A react hook to enable arrow key navigation on a component.
 * @returns a useRef, which can be applied to a component
 */
export function useArrowKeyNavigation(active: boolean = true) {
  const { arrowNavigationEnabled, customVirtualKeyboardEnabled } =
    useFeaturesContext();
  const { setActiveInput } = useVirtualKeyboardContext();
  useEffect(() => {
    if (active) {
      const eventHandler = (event: KeyboardEvent) => {
        handleEvents({
          event,
          showKeyboardCallback: (element) => {
            if (arrowNavigationEnabled) {
              if ((window as any)["Hisense_enableVKB"]) {
                (window as any).Hisense_enableVKB();
              } else if (customVirtualKeyboardEnabled) {
                setActiveInput(element);
                element.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }
            }
          },
        });
      };
      document.addEventListener("keydown", eventHandler);
      return () => {
        console.log("UNMOUNTING useArrowKeyNavigation");
        document.removeEventListener("keydown", eventHandler);
      };
    }
  }, [active]);
}
function focusItem(item: HTMLElement) {
  item.setAttribute(NAVIGATION_LAST_ACTIVE_ITEM_ATTRIBUTE, "true");
  item.focus({ preventScroll: true });
}

export function scrollRowIntoView(item: HTMLElement) {
  if (item && !item.getAttribute("noscroll")) {
    item.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }
}

function hasGridWrapper(row: HTMLElement) {
  if (row) {
    const closestGrid = row.closest(
      NAVIGATION_GRID_SELECTOR
    ) as HTMLElement;
    return closestGrid?.getAttribute(NAVIGATION_GRID_ATTRIBUTE) === "true";
  }
  return false;
}

function handleKeyPress({
  parentNode,
  event,
  activeItemIndex,
  activeRowItems,
  availableRows,
  activeRowIndex,
  activeItem,
  showKeyboardCallback,
}: {
  event: KeyboardEvent;
  activeItemIndex: number;
  activeRowItems: HTMLElement[];
  availableRows: HTMLElement[];
  activeRowIndex: number;
  activeItem: HTMLElement | null;
  parentNode: HTMLElement;
  showKeyboardCallback: (element: HTMLInputElement) => void;
}) {
  if (
    event.key === "Enter" ||
    event.keyCode === 13 ||
    ((window as any)["VK_ENTER"] &&
      event.keyCode === (window as any)["VK_ENTER"])
  ) {
    if (activeItemIndex === -1) return;
    try {
      const path = event?.composedPath() as HTMLElement[];
      console.log("Enter pressed, composedPath: ", path);
      console.log("Active element:", document.activeElement, path[0].nodeName);
      if (path[0].nodeName !== "INPUT" && path[0].nodeName !== "TEXTAREA") {
        activeItem?.click();
        event.preventDefault();
      } else if (activeItem) {
        showKeyboardCallback(activeItem as HTMLInputElement);
      }
    } catch (e) {
      activeItem?.click();
    }
  } else {
    const activeRow = availableRows[activeRowIndex];
    let nextItem: HTMLElement | null = null;
    if (
      event.key === "ArrowDown" ||
      event.keyCode === 40 ||
      ((window as any)["VK_DOWN"] && event.keyCode === (window as any)["VK_DOWN"])
    ) {
      const nextRow = availableRows[activeRowIndex + 1];

      if (nextRow) {
        const nextRowItems = Array.from(
          nextRow.querySelectorAll(NAVIGATION_ITEM_SELECTOR)
        ) as HTMLElement[];
        const lastActiveRow = parentNode.querySelector(
          NAVIGATION_LAST_ACTIVE_ROW_SELECTOR
        ) as HTMLElement;
        if (lastActiveRow) {
          lastActiveRow.removeAttribute(NAVIGATION_LAST_ACTIVE_ROW_ATTRIBUTE);
        }
        nextRow.setAttribute(NAVIGATION_LAST_ACTIVE_ROW_ATTRIBUTE, "true");
        const previousActiveItem = nextRow.querySelector(
          NAVIGATION_LAST_ACTIVE_ITEM_SELECTOR
        ) as HTMLElement;
        const nextRowSyncPositionId = nextRow.getAttribute(
          NAVIGATION_SYNC_SCROLL_POSITION_ATTRIBUTE
        );
        const activeRowSyncPositionId = availableRows[
          activeRowIndex
        ]?.getAttribute(NAVIGATION_SYNC_SCROLL_POSITION_ATTRIBUTE);
        if (hasGridWrapper(nextRow) && hasGridWrapper(activeRow)) {
          const rowsRelation = nextRowItems.length / activeRowItems.length;
          const nextMappedIndex = rowsRelation * activeItemIndex;
          const nextRowItemIndex = Math.round(nextMappedIndex);
          nextItem =
            nextRowItems[
            nextRowItemIndex < 0
              ? 0
              : nextRowItemIndex >= nextRowItems.length
                ? nextRowItems.length - 1
                : nextRowItemIndex
            ];
        } else {
          if (
            nextRowSyncPositionId &&
            activeRowSyncPositionId &&
            nextRowSyncPositionId === activeRowSyncPositionId &&
            activeItemIndex > 0 &&
            activeItemIndex < nextRowItems.length
          ) {
            nextItem = nextRowItems[activeItemIndex];
          } else {
            if (previousActiveItem) {
              nextItem = previousActiveItem;
            } else if (nextRowItems[0]) {
              nextItem = nextRowItems[0];
            }
          }
        }
        if (nextItem) {
          scrollRowIntoView(nextItem);
        }
      }
    }

    if (
      event.key === "ArrowUp" ||
      event.keyCode === 38 ||
      ((window as any)["VK_UP"] && event.keyCode === (window as any)["VK_UP"])
    ) {
      const previousRow = availableRows[activeRowIndex - 1];

      if (previousRow) {
        const previousRowItems = Array.from(
          previousRow.querySelectorAll(NAVIGATION_ITEM_SELECTOR)
        ) as HTMLElement[];
        const lastActiveRow = parentNode.querySelector(
          NAVIGATION_LAST_ACTIVE_ROW_SELECTOR
        ) as HTMLElement;
        if (lastActiveRow) {
          lastActiveRow.removeAttribute(NAVIGATION_LAST_ACTIVE_ROW_ATTRIBUTE);
        }
        previousRow.setAttribute(NAVIGATION_LAST_ACTIVE_ROW_ATTRIBUTE, "true");
        const previousActiveItem = previousRow.querySelector(
          `[${NAVIGATION_LAST_ACTIVE_ITEM_ATTRIBUTE}]`
        ) as HTMLElement;
        const previousRowSyncPositionId = previousRow.getAttribute(
          NAVIGATION_SYNC_SCROLL_POSITION_ATTRIBUTE
        );
        const activeRowSyncPositionId = availableRows[
          activeRowIndex
        ].getAttribute(NAVIGATION_SYNC_SCROLL_POSITION_ATTRIBUTE);
        if (hasGridWrapper(previousRow) && hasGridWrapper(activeRow)) {
          const rowsRelation = previousRowItems.length / activeRowItems.length;
          const previousMappedIndex = rowsRelation * activeItemIndex;
          const previousRowItemIndex = Math.round(previousMappedIndex);
          nextItem =
            previousRowItems[
            previousRowItemIndex < 0
              ? 0
              : previousRowItemIndex >= previousRowItems.length
                ? previousRowItems.length - 1
                : previousRowItemIndex
            ];
        } else {
          if (
            previousRowSyncPositionId &&
            activeRowSyncPositionId &&
            previousRowSyncPositionId === activeRowSyncPositionId &&
            activeItemIndex > 0 &&
            activeItemIndex < previousRowItems.length
          ) {
            nextItem = previousRowItems[activeItemIndex];
          } else {
            if (previousActiveItem) {
              nextItem = previousActiveItem;
            } else if (previousRowItems[0]) {
              nextItem = previousRowItems[0];
            }
          }
        }
        if (nextItem) {
          scrollRowIntoView(nextItem);
        }
      }
    }

    if (
      event.key === "ArrowRight" ||
      event.keyCode === 39 ||
      ((window as any)["VK_RIGHT"] &&
        event.keyCode === (window as any)["VK_RIGHT"])
    ) {
      if (activeItemIndex < activeRowItems.length - 1) {
        const width = activeRowItems[activeItemIndex + 1].clientWidth;
        if (activeRow.dataset.navigateScrollable) {
          if (
            activeRow.dataset.navigateScrollableParent &&
            activeRow.parentNode
          ) {
            (activeRow.parentNode as HTMLElement).scrollTo({
              left: width * (activeItemIndex + 1),
              behavior: "smooth",
            });
          } else {
            activeRow.scrollTo({
              left: width * (activeItemIndex + 1),
              behavior: "smooth",
            });
          }
        }
        nextItem = activeRowItems[activeItemIndex + 1];
        activeItem?.removeAttribute(NAVIGATION_LAST_ACTIVE_ITEM_ATTRIBUTE);
      }
    }

    if (
      event.key === "ArrowLeft" ||
      event.keyCode === 37 ||
      ((window as any)["VK_LEFT"] && event.keyCode === (window as any)["VK_LEFT"])
    ) {
      if (activeItemIndex > 0) {
        const width = activeRowItems[activeItemIndex - 1].clientWidth;
        if (activeRow.dataset.navigateScrollable) {
          if (
            activeRow.dataset.navigateScrollableParent &&
            activeRow.parentNode
          ) {
            (activeRow.parentNode as HTMLElement).scrollTo({
              left: (activeItemIndex - 1) * width,
              behavior: "smooth",
            });
          } else {
            activeRow.scrollTo({
              left: (activeItemIndex - 1) * width,
              behavior: "smooth",
            });
          }
        }
        nextItem = activeRowItems[activeItemIndex - 1];
        activeItem?.removeAttribute(NAVIGATION_LAST_ACTIVE_ITEM_ATTRIBUTE);
      }
    }

    if (nextItem) {
      focusItem(nextItem);
    }
  }

  event.preventDefault();
}

/**
 * Implement arrow key navigation for the given parentNode
 * @param {object}  options
 * @param {Event}   options.e          Keydown event
 * @param {DOMNode} options.parentNode The parent node to operate on. Arrow keys won't navigate outside of this node
 * @param {String}  options.selectors  Selectors for elements we want to be able to key through
 */
export default function handleEvents({
  event,
  showKeyboardCallback,
}: {
  event: KeyboardEvent;
  showKeyboardCallback: (element: HTMLInputElement) => void;
}) {
  // console.log("KEYBOARD_EVENT", event.key, event.keyCode);
  const overlays = Array.from(
    document.querySelectorAll(`[${NAVIGATION_OVERLAY_ATTRIBUTE}]`)
  );
  const parentNode =
    (overlays.length > 0 ? overlays[overlays.length - 1] : document.body) as HTMLElement;
  const key = event.key;
  if (
    (key &&
      !["ArrowUp", "ArrowDown", "Enter", "ArrowRight", "ArrowLeft"].includes(key)) ||
    ![40, 39, 38, 37, 13].includes(event.keyCode)
  ) {
    return;
  }
  let activeElement = document.activeElement !== document.body && parentNode.contains(
    document.activeElement as HTMLElement
  )
    ? (document.activeElement as HTMLElement)
    : null;
  // If we're not inside the container, don't do anything
  // if (!parentNode.contains(activeElement)) return;
  const availableRows = Array.from(
    parentNode.querySelectorAll(NAVIGATION_ROW_SELECTOR)
  ) as HTMLElement[];
  let activeRowIndex = 0;
  if (activeElement) {
    const closestRow = activeElement.closest(
      NAVIGATION_ROW_SELECTOR
    ) as HTMLElement;
    if (closestRow) {
      const availableRowsIndex = availableRows.indexOf(closestRow);
      if (availableRowsIndex >= 0) {
        activeRowIndex = availableRowsIndex;
      }
    }
  } else {
    const lastActiveRow = parentNode.querySelector(
      NAVIGATION_LAST_ACTIVE_ROW_SELECTOR
    ) as HTMLElement;
    if (lastActiveRow) {
      const availableRowsIndex = availableRows.indexOf(lastActiveRow);
      if (availableRowsIndex >= 0) {
        const lastActiveItem = lastActiveRow.querySelector(NAVIGATION_LAST_ACTIVE_ITEM_SELECTOR) as HTMLElement | null;
        if (lastActiveItem) {
          activeElement = lastActiveItem;
        }
        activeRowIndex = availableRowsIndex;
      }
    }
  }


  const activeRow = availableRows[activeRowIndex] || null;
  // Get the list of elements we're allowed to scroll through
  const availableItems = activeRow
    ? (Array.from(
      activeRow.querySelectorAll(NAVIGATION_ITEM_SELECTOR)
    ) as HTMLElement[])
    : ([] as HTMLElement[]);

  const activeElementIndex = Array.from(availableItems).findIndex(
    (availableElement) => availableElement === activeElement
  );
  if (activeElementIndex === -1 && availableItems[0]) {
    availableItems[0].focus();
  }
  handleKeyPress({
    parentNode,
    event,
    activeItemIndex: activeElementIndex,
    activeRowItems: availableItems,
    availableRows: availableRows,
    activeRowIndex,
    activeItem: activeElement,
    showKeyboardCallback,
  });
}
